import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'rebass'

const Badge = props => (
  <Card
    {...props}
    borderRadius={9999}
    color="brown"
    style={{ display: 'inline-block' }}
    fontSize={0}
    p={2}
    py={1}
  />
)

Badge.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Badge
