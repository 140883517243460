import React from 'react'
import { graphql } from 'gatsby'
import NoSSR from 'react-no-ssr'

import Layout from 'components/layout'
import CookiesBanner from 'components/cookiesbanner'
import SEO from 'components/seo'
import Calendar from 'calendar/components/calendar'

const Index = ({ data }) => (
  <Layout>
    <SEO title="Calendario Voleyplaya Barcelona" />
    <NoSSR>
      <CookiesBanner />
    </NoSSR>
    <Calendar {...data} />
  </Layout>
)

export const pageQuery = graphql`
  query allEvents {
    allAirtable(
      filter: { queryName: { eq: "Events" } }
      sort: { fields: data___date, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            createdTime
            date
            format
            image {
              url
            }
            organizer {
              data {
                slug
                url
                fullName
                shortName
                image {
                  thumbnails {
                    large {
                      url
                      width
                      height
                    }
                  }
                }
              }
            }
            place {
              data {
                name
                address
              }
            }
            status
            title
            url
          }
        }
      }
    }
  }
`

export default Index
