import React from 'react'
import { Flex, Box } from 'rebass'
import { TODOS } from 'calendar/constants'

const Filters = ({ elements, activeElement, filterFn }) => {
  elements.unshift(TODOS)
  return (
    <Flex justifyContent={['space-evenly', 'normal', 'normal']}>
      {elements.map((elm, i) => {
        const isActive = activeElement === elm
        return (
          <Box
            mr={[0, 2, 2]}
            p={2}
            bg={isActive ? 'brown' : 'transparent'}
            onClick={() => (isActive ? null : filterFn(elm))}
            key={i}
            style={{
              cursor: isActive ? 'default' : 'pointer',
            }}
          >
            {elm}
          </Box>
        )
      })}
    </Flex>
  )
}

export default Filters
