import React from 'react'
import { Flex, Box } from 'rebass'
import Cookies from 'universal-cookie'

import { ACCEPTED_COOKIES_TOKEN } from 'constants.js'
import { CenteredWrapper } from 'calendar/components'

class CookiesBanner extends React.Component {
  constructor(props) {
    super(props)

    this.cookies = new Cookies()
    this.state = { accepted: this.cookies.get(ACCEPTED_COOKIES_TOKEN) }
  }

  handleCookie = () => {
    this.cookies.set(ACCEPTED_COOKIES_TOKEN, true, { path: '/' })
    this.setState({ accepted: true })
  }

  render() {
    return this.state.accepted ? (
      <div />
    ) : (
      <div
        style={{
          background: 'black',
          color: 'white',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <CenteredWrapper>
          <Flex alignItems="center" fontSize={0} px={1}>
            <Box flex={1} pl={1}>
              <span role="img" aria-label="Coojie emoji">
                🍪
              </span>
              Usamos cookies propias y&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              >
                de terceros
              </a>
            </Box>
            <Box
              onClick={() => this.handleCookie()}
              bg="brown"
              color="white"
              px={2}
              py={1}
              m={1}
              mr={0}
              style={{ cursor: 'pointer' }}
            >
              Aceptar
            </Box>
          </Flex>
        </CenteredWrapper>
      </div>
    )
  }
}

export default CookiesBanner
