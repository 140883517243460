import React from 'react'
import { Flex, Box, Heading, Text } from 'rebass'
import { Lock, MapMarkerAlt } from 'styled-icons/fa-solid'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import format from 'date-fns/format'
import es from 'date-fns/locale/es'

import { Avatar } from 'calendar/components/'
import Badges from './badges'

import './event.css'

const NewLabel = () => <Box className="list-item__new-label">¡Nuevo!</Box>

const Day = ({ date }) => {
  const weekDay = format(date, 'E', { locale: es })
  const monthDay = format(date, 'dd')

  return (
    <Box m={2}>
      <Text textAlign={'center'} fontSize={1} color={'#999'}>
        {weekDay}
      </Text>
      <Text fontSize={5} color={'#999'} mt={-2}>
        {monthDay}
      </Text>
    </Box>
  )
}

const infoUrl = (image, url, organizer) =>
  url ? url : image ? image[0].url : organizer.url

const Event = ({
  date,
  format: allFormats,
  image,
  organizer,
  place,
  title,
  url,
  ...props
}) => {
  const eventUrl = infoUrl(image, url, organizer)
  let cssClass = 'list-item'
  if (props.private) cssClass += ' list-item--private'

  return (
    <Flex alignItems="center" className={cssClass}>
      <Day date={date} />
      <Box flex={1} ml={3}>
        <Heading fontSize={3}>
          <OutboundLink href={eventUrl}>{title}</OutboundLink>
        </Heading>
        <Text>
          <MapMarkerAlt size={16} color={'#999'} /> {place[0].data.name}
        </Text>
        {allFormats && <Badges allFormats={allFormats} />}
      </Box>

      {props.private ? (
        <Lock size="48" color="#ccc" />
      ) : (
        <OutboundLink href={eventUrl}>
          <Avatar src={organizer.image} alt={organizer.fullName} />
        </OutboundLink>
      )}
    </Flex>
  )
}

// https://calendar.google.com/calendar/r/eventedit?location=Playa+de+Bogatell+-+Paseo+Maritimo+del+Bogatell,+80,+08005++-+Barcelona,+es&sprop=name:~+Event+Name+~&details=this+are+the+details&text=this+is+the+text&dates=20190101T110000Z/20190101T130000Z&sf=true
export default Event
