import React from 'react'
import { Card } from 'rebass'

const MonthEvents = props => (
  <Card
    bg="#fff"
    borderRadius={[0, '4px', '4px']}
    style={{ border: '1px solid #ddd' }}
    {...props}
  />
)

export default MonthEvents
