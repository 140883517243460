import React from 'react'
import { Flex, Box, Heading } from 'rebass'
import { FEMENINO, MASCULINO, MIXTO, TODOS, FOUR } from 'calendar/constants'
import calendar from 'calendar/helpers'
import {
  CenteredWrapper,
  Event,
  Filters,
  HeroBanner,
  MonthEvents,
} from 'calendar/components'

class Calendar extends React.Component {
  constructor(props) {
    super(props)

    const data = this.props
    const allEvents = calendar.cleanData(data)
    const monthsWithAllEvents = calendar.buildCalendar(allEvents)

    this.state = {
      activeFilter: TODOS,
      allEvents: monthsWithAllEvents,
      [MASCULINO]: calendar.filterEvents(monthsWithAllEvents, MASCULINO),
      [FEMENINO]: calendar.filterEvents(monthsWithAllEvents, FEMENINO),
      [MIXTO]: calendar.filterEvents(monthsWithAllEvents, MIXTO),
      [FOUR]: calendar.filterEvents(monthsWithAllEvents, FOUR),
    }
  }

  filter = activeFilter => this.setState({ activeFilter })

  render() {
    let allMonths = this.state[this.state.activeFilter] || this.state.allEvents

    return (
      <>
        <HeroBanner />

        <Box bg={'#000'} color={'#fff'}>
          <CenteredWrapper>
            <Filters
              activeElement={this.state.activeFilter}
              elements={[FEMENINO, MASCULINO, MIXTO, FOUR]}
              filterFn={this.filter}
            />
          </CenteredWrapper>
        </Box>

        <Flex flexDirection={['column', 'row', 'row']} mx={[0, 3, 3]}>
          <CenteredWrapper>
            {allMonths.map(({ name, events }, i) => (
              <section key={i}>
                <Heading as="h3" mt={2} mb={1} ml={[2, 1, 1]}>
                  {name}
                </Heading>

                <MonthEvents mb={[0, 3, 3]}>
                  {events.map((event, i) => (
                    <Event key={event.id} {...event} />
                  ))}
                </MonthEvents>
              </section>
            ))}
          </CenteredWrapper>
        </Flex>
      </>
    )
  }
}

export default Calendar
