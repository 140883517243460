import React from 'react'
import { FEMENINO, MASCULINO, MIXTO } from 'calendar/constants'
import { Badge } from 'calendar/components'

const colors = {
  [FEMENINO]: '#facbda',
  [MASCULINO]: '#fabada',
  [MIXTO]: '#cbbada',
}

const Badges = ({ allFormats }) =>
  allFormats
    .sort()
    .map((format, i) => (
      <Badge key={format} mt={1} mr={2} bg={colors[format]} children={format} />
    ))

export default Badges
