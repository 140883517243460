import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'rebass'
import { VolleyballBall } from 'styled-icons/fa-solid'

const Avatar = props =>
  props.src ? (
    <Image
      {...props}
      fontSize={0}
      height={props.height}
      width={props.width}
      borderRadius={9999}
      style={{ overflow: 'hidden' }}
    />
  ) : (
    <VolleyballBall width={props.width} color="#eee" />
  )

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
}

Avatar.defaultProps = {
  height: 48,
  width: 48,
}

export default Avatar
