import React from 'react'
import { Card } from 'rebass'
import './heroBanner.css'

const HeroBanner = () => (
  <Card p={2} className="hero">
    <h2 className="hero__title" ml={3} mb={0} as={'h2'}>
      Torneos voleyplaya Barcelona
    </h2>
    {/* <small className="hero__credit">Foto: Inés</small> */}
  </Card>
)

export default HeroBanner
