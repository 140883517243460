import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import subDays from 'date-fns/subDays'
import es from 'date-fns/locale/es'

const cleanImage = imageNode => {
  if (!imageNode || !imageNode[0]) {
    return
  }

  return imageNode[0].thumbnails.large.url
}

const cleanOrganizer = organizerNode => {
  if (!organizerNode || !organizerNode[0]) {
    return
  }
  const data = organizerNode[0].data

  return {
    fullName: data.fullName,
    shortName: data.shortName,
    slug: data.slug,
    url: data.url,
    image: cleanImage(data.image),
  }
}

const isValidEvent = (event, validation) =>
  event.format && event.format.includes(validation)

const is4vs4 = event => event.title.toLowerCase().includes('4x4')

const calendar = {
  buildCalendar: allEvents => {
    let months = {}

    allEvents.map(event => {
      const id = format(event.date, 'yyyyMM')

      if (!months[id]) {
        months[id] = {
          name: format(event.date, 'MMMM yyyy', { locale: es }),
          events: [],
        }
      }

      return months[id].events.push(event)
    })

    return Object.values(months)
  },
  cleanData: data => {
    const aWeekAgo = subDays(new Date(), 7)

    return data.allAirtable.edges
      .map(edge => {
        const nodeData = edge.node.data
        nodeData.id = edge.node.id
        nodeData.organizer = cleanOrganizer(nodeData.organizer)
        nodeData.date = new Date(nodeData.date)
        nodeData.isNewEvent = isAfter(nodeData.createdTime, aWeekAgo)

        return nodeData
      })
      .filter(event => {
        const today = Date.now()
        const yesterday = subDays(today, 1)

        return isAfter(event.date, yesterday)
      })
  },
  filterEvents: (allMonths, format) => {
    const validationFn = event =>
      format === '4vs4' ? is4vs4(event) : isValidEvent(event, format)

    return allMonths
      .map(month =>
        Object.assign({}, month, {
          events: month.events.filter(event => validationFn(event)),
        })
      )
      .filter(month => month.events.length !== 0)
  },
}

export default calendar
