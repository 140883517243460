import React from 'react'
import { Box } from 'rebass'

const CenteredWrapper = props => (
  <Box
    style={{
      width: '100%',
      maxWidth: '50em',
      margin: '0 auto',
    }}
    {...props}
  />
)

export default CenteredWrapper
